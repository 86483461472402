import React from "react";
import { Link } from 'react-router-dom';
import './Aside.css'
import logo from '../../assets/unlimited_logo.PNG'
import AsideLogo from "./AsideLogo";
import AsideItem from "./AsideItem";

import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine'
import { faChartPie } from '@fortawesome/free-solid-svg-icons/faChartPie'
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus'
import { faUserCheck } from '@fortawesome/free-solid-svg-icons/faUserCheck'
import { faUserXmark } from '@fortawesome/free-solid-svg-icons/faUserXmark'
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear'
import { faUserEdit } from '@fortawesome/free-solid-svg-icons/faUserEdit'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp'
import { faShopify } from '@fortawesome/free-brands-svg-icons/faShopify'

const Aside = props => {
    const { currState, setCurrState } = props
    if (!currState.auth) return <></>
    const { permissions } = currState.auth
    // console.log(permissions)
    return (
        <aside className="aside">
            <AsideLogo src={logo} />
            {permissions.leads && <Link to="/leads"><AsideItem title="Leads" icon={faUsers} /></Link>}
            {/* <Link to="/qualificados"><AsideItem title="Qualificados" icon={faUserCheck} /></Link>
        <Link to="/desqualificados"><AsideItem title="Desqualificados" icon={faUserXmark} /></Link> */}
            {permissions.relatorios && <Link to="/relatorios"><AsideItem title="Relatórios" icon={faChartLine} /></Link>}
            {<Link to="/config"><AsideItem title="Configurações" icon={faGear} /></Link>}
        </aside>
    )
}

export default Aside