import React from "react";
import { useState, useEffect, useRef } from "react";
import './Leads.css'
import logo from '../../assets/unlimited_logo.PNG'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';
import { useAuthUser } from 'react-auth-kit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp'
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook'
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe'
import { faClose } from '@fortawesome/free-solid-svg-icons/faClose'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment'
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons/faCalendarDay'
import { faMoneyBillTrendUp } from '@fortawesome/free-solid-svg-icons/faMoneyBillTrendUp'

const Leads = props => {
    const auth = useAuthUser()
    const [leads, setLeads] = useState([])
    const [leadPopUpIsOpen, setLeadPopUpIsOpen] = useState(false)
    const [leadPopUpInfo, setLeadPopUpInfo] = useState({})
    const [dataNovoEvento, setDataNovoEvento] = useState(new Date())

    const useEffetDone = useRef()

    useEffect(() => {
        if (!useEffetDone.ok) {
            fetchLeads()
            useEffetDone.ok = true
        }

    }, [])

    const fetchLeads = async () => {
        let res = await fetch('/api/crm/leads',
            {
                method: 'POST', headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    website_url: auth().website_url
                })
            })
        let result = await res.json()
        if (result.ok) {
            setLeads([...result.data])
            console.log([...result.data])

        } else {
            window.alert('else')
        }
    }

    const openLeadPopUp = id => {
        document.querySelector('main').style.filter = "blur(3px)"
        setLeadPopUpInfo(leads.filter(lead => lead.id == id)[0])
        setLeadPopUpIsOpen(true)
    }
    const closeLeadPopUp = id => {
        document.querySelector('main').style.filter = ""
        setLeadPopUpIsOpen(false)
    }

    const formatDateTime = datetime => {
        const options = {
            day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit',
            minute: '2-digit',
            hour12: false, timeZone: 'America/Argentina/Buenos_Aires'
        };
        const formattedDate = new Date(datetime).toLocaleDateString('pt-BR', options);
        return formattedDate
    }

    const renderLeads = () => (
        <main>
            <header className="leads-header">Leads de Formulário - {leads.length} Contatos</header>
            <table className="unlimited-leads">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Contato</th>
                        <th>Origem</th>
                        <th>Data 1º Contato</th>
                        <th>Etapa da Jornada</th>
                    </tr>
                </thead>
                <tbody>
                    {leads.map(lead => (
                        renderLeadRow(lead)
                    ))}

                </tbody>
            </table>
        </main>
    )
    const getLeadOrigin = lead => {
        let leadOrigin = <div>
            <FontAwesomeIcon icon={faGlobe} />
            <p>Orgânico</p>
        </div>
        if (lead.page_url.includes('fbclid') || lead.fbp || lead.fbc) {
            leadOrigin = <div>
                <FontAwesomeIcon icon={faFacebook} />
                <p>Meta Ads</p>
            </div>
        } else if (lead.page_url.includes('gclid')) {
            leadOrigin = <div>
                <FontAwesomeIcon icon={faGoogle} />
                <p>Google Ads</p>
            </div>
        }
        return leadOrigin
    }
    const getEtapaJornada = lead => {
        let leadEtapa = <div>
            <FontAwesomeIcon icon={faComment} />
            <p>Entrou em contato</p>
        </div>
        if (lead.etapa_jornada == 'Agendou reunião') {
            leadEtapa = <div>
                <FontAwesomeIcon icon={faCalendarDay} />
                <p>Agendou reunião</p>
            </div>
        } else if (lead.etapa_jornada == 'Comprou') {
            leadEtapa = <div>
                <FontAwesomeIcon icon={faMoneyBillTrendUp} />
                <p>Comprou</p>
            </div>
        }
        return leadEtapa
    }
    const renderLeadRow = lead => {

        return (
            <tr key={lead.id} id={lead.id} onClick={() => openLeadPopUp(lead.id)}>
                <td><div>
                    <FontAwesomeIcon icon={faUser} />
                    <p>{lead.first_name}  {lead.last_name}</p>
                </div></td>
                <td>
                    <div>
                        <FontAwesomeIcon icon={faWhatsapp} />
                        <p>({lead.phone.substring(0, 2)}) {lead.phone.substring(2)}</p>
                    </div>
                    <div>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <p>{lead.email}</p>
                    </div>

                </td>
                <td>
                    {getLeadOrigin(lead)}
                </td>
                <td>
                    <div>
                        <FontAwesomeIcon icon={faCalendar} />
                        <p>{formatDateTime(lead.datetime)}</p>
                    </div>
                </td>
                <td>{getEtapaJornada(lead)}</td>
            </tr>
        )
    }

    const renderLeadStageChanger = (lead) => {

        return (
            <>
                <div>
                    <div className="divisor">
                        <img src={logo} alt="" />
                    </div>
                    <h3 style={{ marginTop: '10px' }}>Atualizar etapa da jornada</h3>
                </div>
                <div className="popup-row" style={{ alignItems: 'center' }}>
                    <p>Novo status:</p>
                    <select id="novo-status-lead" name="novo-status-lead">
                        {auth().etapas_jornada.filter(etapa => etapa.required_stage == lead.etapa_jornada).map(etapa => (
                            <option value={etapa.nome_etapa}>{etapa.nome_etapa}</option>
                        ))}
                    </select>
                </div>
                <div className="popup-row" style={{ alignItems: 'center' }}>
                    <p>Data do evento:</p>
                    <DatePicker locale={ptBR} selected={dataNovoEvento}
                        onChange={(date) => setDataNovoEvento(date)}
                        dateFormat='dd/MM/yyyy@HH:mm' showTimeSelect
                        timeFormat="HH:mm" timeIntervals={15} />

                </div>

                {
                    (leadPopUpInfo.fbp || leadPopUpInfo.fbc) &&
                    <div className="popup-row">
                        <p>Enviar evento ao Meta Ads</p>
                    </div>

                }
            </>
        )
    }

    const renderPopUpRow = (name, value, icon = false) => (
        <div className="popup-row">
            <div className="popup-row-item">
                <p>{name}</p>
            </div>
            <div className="popup-row-item">
                {icon && <FontAwesomeIcon icon={icon} />}
                <p>{value}</p>
            </div>

        </div>
    )
    const renderPopUp = () => (
        <div className="lead-popup">
            <div className="first-row">
                <h2 style={{ marginRight: 'auto' }}>Informações do Lead</h2>
                <FontAwesomeIcon icon={faClose} size="xl" onClick={closeLeadPopUp} />
            </div>
            <div className="popup-infos">
                {renderPopUpRow('Data do contato:', formatDateTime(leadPopUpInfo.datetime), faCalendar)}
                {renderPopUpRow('Nome completo:', leadPopUpInfo.first_name + ' ' + leadPopUpInfo.last_name, faUser)}
                {renderPopUpRow('Telefone:', leadPopUpInfo.phone.substring(0, 2) + ' ' + leadPopUpInfo.phone.substring(2), faWhatsapp)}
                {renderPopUpRow('Email:', leadPopUpInfo.email, faEnvelope)}

                <div className="popup-row">
                    <div className="popup-row-item">
                        <p>Origem:</p>
                    </div>
                    <div className="popup-row-item">
                        {getLeadOrigin(leadPopUpInfo)}
                    </div>
                </div>
                <br></br>
                <div className="popup-row">
                    <div className="popup-row-item">
                        <p>Etapa da Jornada:</p>
                    </div>
                    <div className="popup-row-item">
                        {getEtapaJornada(leadPopUpInfo)}
                    </div>
                </div>

                {renderLeadStageChanger(leadPopUpInfo)}

            </div>
        </div>
    )

    return (
        <>
            {
                (!auth().website_url || (auth() && auth().website_url.length == 0)) ?
                    <h4 style={{ margin: 'auto' }}>Nenhum URL de origem configurado.</h4>
                    :
                    renderLeads()
            }
            {leadPopUpIsOpen &&
                renderPopUp()
            }
        </>
    )
}

export default Leads