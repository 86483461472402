export async function submitLogin(username, password) {
    try {
        let res = await fetch('/api/crm/login',
            {
                method: 'POST', headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    username: username,
                    password: password
                })
            })
        let data = await res.json()
        return data
    }
    catch (e) {
        console.log(e)
    }
}