export function getStringDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function formatDataToEachDayObject(data) {
    let formattedData = {}
    for (let item of data) {
        formattedData[item.date_start] = { ...item }
    }
    return formattedData
}

