import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop } from '@fortawesome/free-solid-svg-icons/faDesktop'

const MobileWarning = () => (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center', boxSizing: 'border-box', padding: '0px 20px' }}>
        <FontAwesomeIcon icon={faDesktop} size="2xl" />
        <p style={{ marginTop: '10px' }}>Atualmente a aplicação é suportada apenas em desktops e notebooks.</p>
    </div>
)

export default MobileWarning