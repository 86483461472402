import React from "react";
import ConfigField from "./ConfigField";
import './Config.css'

const Config = props => {
    const { currState, setCurrState } = props

    return (
        <div className="main-container">
            <header>
                <p>Configurações</p>
            </header>
            <div className="config-list">
                <ConfigField name="ID da Conta de Anúncios" value={currState.auth.ad_account_id} type="text" />
                <ConfigField name="Token de Acesso aos Insights" value={currState.auth.insights_access_token} type="password" />
            </div>
        </div>
    )
}

export default Config