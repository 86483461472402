import React from "react";
import { useEffect, useState } from "react";
import { useRef } from "react";

const DataRequestComponent = props => {
    const { currState, setCurrState, fetchUrl, fecthBody, stateProp } = props
    const firstRun = useRef()

    const [fetchLoading, setFetchLoading] = useState(false)

    useEffect(() => {
        if (!firstRun.done && !currState[stateProp]) {
            setCurrState({ ...currState, loading: true })
            fetchData()

        }
        firstRun.done = true
    }, []);

    useEffect(() => {
        fetchData()
    }, [fetchUrl]);


    const fetchData = async () => {
        return new Promise(async (resolve) => {
            setFetchLoading(true)
            try {
                let res = await fetch(fetchUrl)
                let data = await res.json()
                let newState = { ...currState }
                newState[stateProp] = { ...data }
                setCurrState({ ...newState })
                // console.log(fetchUrl)
                // console.log(newState)
            } catch (e) {
                window.alert(e)
            }
            setFetchLoading(false)
            resolve()
        })

    }


    return (
        <>
            {currState[stateProp] ?
                <>
                    {fetchLoading && <div className="loading-overlay"><span class="loader"></span></div>}
                    {props.children}
                </>
                : <div className="loading-overlay"><span class="loader"></span></div>}
        </>
    )
}

export default DataRequestComponent