import "./App.css";
import React, { useState, useRef } from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import Aside from "./components/Aside/Aside";
import Login from "./components/Login/Login";
import Leads from "./components/Leads/Leads";
import Relatorios from "./components/Relatorios/Relatorios";
import Config from "./components/Config/Config";
import MobileWarning from "./components/Mobile/MobileWarning";


import { useIsAuthenticated } from "react-auth-kit";
import { useAuthUser } from 'react-auth-kit'

import { submitLogin } from "./utils/login";

function App() {
  let oneMonthAgo = new Date()
  oneMonthAgo.setDate(oneMonthAgo.getDate() - 30);
  const today = new Date()
  const [currState, setCurrState] = useState({
    dataInicio: oneMonthAgo,
    dataFim: today,
    auth: null
  });
  const authDone = useRef()

  const isAuthenticated = useIsAuthenticated();
  const auth = useAuthUser()

  const getAuthData = async () => {
    authDone.ok = true
    const loginData = await submitLogin(auth().username, auth().password)
    setCurrState({ ...currState, auth: loginData.data })
  }



  const AuthElement = (Component) => {
    if (!isAuthenticated()) return <Login currState={currState} setCurrState={setCurrState} />
    if (!authDone.ok) getAuthData()
    if (!currState.auth) return <></>
    return Component
  };
  const width = window.innerWidth
  console.log(width)
  const breakpoint = 768; // Define your breakpoint for mobile vs desktop

  return (

    <React.StrictMode>
      {width > breakpoint ?
        <div className="app">
          <Aside currState={currState} setCurrState={setCurrState} />
          <Routes>
            <Route element={AuthElement(<Relatorios currState={currState} setCurrState={setCurrState} />)} path="/" exact />
            <Route element={AuthElement(<Relatorios currState={currState} setCurrState={setCurrState} />)} path="*" exact />
            <Route element={AuthElement(<Leads currState={currState} setCurrState={setCurrState} />)} path="/leads" exact />
            <Route element={AuthElement(<Config currState={currState} setCurrState={setCurrState} />)} path="/config" exact />
            <Route
              element={AuthElement(
                <Relatorios currState={currState} setCurrState={setCurrState} />
              )}
              path="/relatorios"
            />
          </Routes>
        </div>
        :
        <MobileWarning />
      }

    </React.StrictMode >

  );
}

export default App;
