import React from "react";
import { ResponsiveLine } from '@nivo/line'

// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/line

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const LineChartTimeAxis = ({ data, legend }) => {

    const tickValues = data[0].data.length > 9 ? undefined : 'every day'

    return (
        <ResponsiveLine
            colors={d => d.color}
            theme={{
                textColor: 'hsla(360, 0%, 63%, 1)'
            }}
            animate
            axisBottom={{
                format: '%b %d',
                legend: 'Data',
                legendOffset: 40,
                tickValues: tickValues,
                legendPosition: 'middle'
            }}
            axisLeft={{
                legend: legend,
                legendOffset: -50,
                legendPosition: 'middle'
            }}
            curve="monotoneX"
            enableGridX={false}
            enableGridY={false}
            data={data}
            // enablePointLabel
            // height={400}
            margin={{
                bottom: 60,
                left: 100,
                right: 80,
                top: 40
            }}
            enableArea={true}
            pointColor={d => d.color}
            pointBorderWidth={1}
            pointSize={8}
            // pointSymbol={function noRefCheck() { }}
            useMesh
            // width={900}
            xFormat="time:%d-%m-%Y"
            xScale={{
                format: '%Y-%m-%d',
                precision: 'day',
                type: 'time',
                useUTC: false
            }}
            yScale={{
                type: 'linear'
            }}
        />
    )
}

export default LineChartTimeAxis