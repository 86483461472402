import React, { useState } from "react";
import './Relatorios.css'
import Box from "./Box";
import LineChartTimeAxis from "./LineChartTimeAxis";
import DataRequestComponent from "../DataRequest/DataRequestComponent";
import { getStringDate, formatDataToEachDayObject } from "../../utils/dateFunctions";
import { fillMissingDates, getTotalLeads, getTotalSpend } from "../../utils/relatorios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers'
import { faSackDollar } from '@fortawesome/free-solid-svg-icons/faSackDollar'
import { faCommentsDollar } from '@fortawesome/free-solid-svg-icons/faCommentsDollar'
import { faMoneyBillTrendUp } from '@fortawesome/free-solid-svg-icons/faMoneyBillTrendUp'
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar'

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';

const Relatorios = props => {
    const { currState, setCurrState } = props
    const { dataInicio, dataFim, relatorios } = currState
    const { ad_account_id, insights_access_token } = currState.auth

    const [metric, setMetric] = useState("onsite_conversion.messaging_conversation_started_7d")
    const metricNames = {
        //actions
        "onsite_conversion.messaging_conversation_started_7d": "Leads",
        "post_engagement": "Engajamento com Post",
        "video_view": "Visualização de Vídeo",
        "post_reaction": "Reações em Posts",
        "onsite_conversion.post_save": "Posts Salvos",
        "link_click": "Cliques",
        "spend": "Orçamento",
        "impressions": "Impressões",


    }
    // const exampleData = {
    //     "data": [
    //         {
    //             "impressions": "6520",
    //             "actions": [
    //                 {
    //                     "action_type": "post",
    //                     "value": "2"
    //                 },
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "1158"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "1158"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "6"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "1124"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "8"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "24"
    //                 }
    //             ],
    //             "date_start": "2023-12-19",
    //             "date_stop": "2023-12-19"
    //         },
    //         {
    //             "impressions": "6831",
    //             "actions": [
    //                 {
    //                     "action_type": "post",
    //                     "value": "2"
    //                 },
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "1176"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "1176"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "6"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "1131"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "11"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "32"
    //                 }
    //             ],
    //             "date_start": "2023-12-20",
    //             "date_stop": "2023-12-20"
    //         },
    //         {
    //             "impressions": "7691",
    //             "actions": [
    //                 {
    //                     "action_type": "post",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "1151"
    //                 },
    //                 {
    //                     "action_type": "comment",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "1151"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "7"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "1110"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "6"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "33"
    //                 }
    //             ],
    //             "date_start": "2023-12-21",
    //             "date_stop": "2023-12-21"
    //         },
    //         {
    //             "impressions": "7641",
    //             "actions": [
    //                 {
    //                     "action_type": "post",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "1055"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "1055"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "2"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "1031"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "5"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "18"
    //                 }
    //             ],
    //             "date_start": "2023-12-22",
    //             "date_stop": "2023-12-22"
    //         },
    //         {
    //             "impressions": "7247",
    //             "actions": [
    //                 {
    //                     "action_type": "post",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "1203"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "1203"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "1169"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "9"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "24"
    //                 }
    //             ],
    //             "date_start": "2023-12-23",
    //             "date_stop": "2023-12-23"
    //         },
    //         {
    //             "impressions": "9478",
    //             "actions": [
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "1409"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "1409"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "5"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "1350"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "14"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "45"
    //                 }
    //             ],
    //             "date_start": "2023-12-24",
    //             "date_stop": "2023-12-24"
    //         },
    //         {
    //             "impressions": "11625",
    //             "actions": [
    //                 {
    //                     "action_type": "post",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "1634"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "1634"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "1586"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "16"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "31"
    //                 }
    //             ],
    //             "date_start": "2023-12-25",
    //             "date_stop": "2023-12-25"
    //         },
    //         {
    //             "impressions": "9585",
    //             "actions": [
    //                 {
    //                     "action_type": "onsite_conversion.post_save",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "1683"
    //                 },
    //                 {
    //                     "action_type": "comment",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "1683"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "3"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "1631"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "12"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "38"
    //                 }
    //             ],
    //             "date_start": "2023-12-26",
    //             "date_stop": "2023-12-26"
    //         },
    //         {
    //             "impressions": "9094",
    //             "actions": [
    //                 {
    //                     "action_type": "post",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "1594"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "1594"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "3"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "1548"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "14"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "31"
    //                 }
    //             ],
    //             "date_start": "2023-12-27",
    //             "date_stop": "2023-12-27"
    //         },
    //         {
    //             "impressions": "10491",
    //             "actions": [
    //                 {
    //                     "action_type": "post",
    //                     "value": "2"
    //                 },
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "1596"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "1596"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "6"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "1549"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "12"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "33"
    //                 }
    //             ],
    //             "date_start": "2023-12-28",
    //             "date_stop": "2023-12-28"
    //         },
    //         {
    //             "impressions": "10456",
    //             "actions": [
    //                 {
    //                     "action_type": "onsite_conversion.post_save",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "1854"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "1854"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "9"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "1808"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "9"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "36"
    //                 }
    //             ],
    //             "date_start": "2023-12-29",
    //             "date_stop": "2023-12-29"
    //         },
    //         {
    //             "impressions": "10712",
    //             "actions": [
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "1955"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "1955"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "1920"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "10"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "25"
    //                 }
    //             ],
    //             "date_start": "2023-12-30",
    //             "date_stop": "2023-12-30"
    //         },
    //         {
    //             "impressions": "13818",
    //             "actions": [
    //                 {
    //                     "action_type": "onsite_conversion.post_save",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "2004"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "2004"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "1948"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "13"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "42"
    //                 }
    //             ],
    //             "date_start": "2023-12-31",
    //             "date_stop": "2023-12-31"
    //         },
    //         {
    //             "impressions": "18664",
    //             "actions": [
    //                 {
    //                     "action_type": "onsite_conversion.post_save",
    //                     "value": "2"
    //                 },
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "2460"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "2460"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "5"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "2398"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "9"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "51"
    //                 }
    //             ],
    //             "date_start": "2024-01-01",
    //             "date_stop": "2024-01-01"
    //         },
    //         {
    //             "impressions": "13603",
    //             "actions": [
    //                 {
    //                     "action_type": "onsite_conversion.post_save",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "post",
    //                     "value": "2"
    //                 },
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "2472"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "2472"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "7"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "2409"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "13"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "47"
    //                 }
    //             ],
    //             "date_start": "2024-01-02",
    //             "date_stop": "2024-01-02"
    //         },
    //         {
    //             "impressions": "12699",
    //             "actions": [
    //                 {
    //                     "action_type": "onsite_conversion.post_save",
    //                     "value": "2"
    //                 },
    //                 {
    //                     "action_type": "post",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "2226"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "2226"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "4"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "2172"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "14"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "37"
    //                 }
    //             ],
    //             "date_start": "2024-01-03",
    //             "date_stop": "2024-01-03"
    //         },
    //         {
    //             "impressions": "12584",
    //             "actions": [
    //                 {
    //                     "action_type": "post",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "1996"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "1996"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "8"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "1936"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "14"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "45"
    //                 }
    //             ],
    //             "date_start": "2024-01-04",
    //             "date_stop": "2024-01-04"
    //         },
    //         {
    //             "impressions": "11591",
    //             "actions": [
    //                 {
    //                     "action_type": "onsite_conversion.post_save",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "post",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "1992"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "1992"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "5"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "1957"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "6"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "27"
    //                 }
    //             ],
    //             "date_start": "2024-01-05",
    //             "date_stop": "2024-01-05"
    //         },
    //         {
    //             "impressions": "11627",
    //             "actions": [
    //                 {
    //                     "action_type": "post",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "2007"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "2007"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "3"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "1974"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "10"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "22"
    //                 }
    //             ],
    //             "date_start": "2024-01-06",
    //             "date_stop": "2024-01-06"
    //         },
    //         {
    //             "impressions": "13224",
    //             "actions": [
    //                 {
    //                     "action_type": "post",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "landing_page_view",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "2315"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "2315"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "3"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "2275"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "8"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "31"
    //                 }
    //             ],
    //             "date_start": "2024-01-07",
    //             "date_stop": "2024-01-07"
    //         },
    //         {
    //             "impressions": "9616",
    //             "actions": [
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "1989"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "1989"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "2"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "1958"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "7"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "24"
    //                 }
    //             ],
    //             "date_start": "2024-01-08",
    //             "date_stop": "2024-01-08"
    //         },
    //         {
    //             "impressions": "9096",
    //             "actions": [
    //                 {
    //                     "action_type": "onsite_conversion.post_save",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "post",
    //                     "value": "4"
    //                 },
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "1763"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "1763"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "3"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "1718"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "13"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "27"
    //                 }
    //             ],
    //             "date_start": "2024-01-09",
    //             "date_stop": "2024-01-09"
    //         },
    //         {
    //             "impressions": "10054",
    //             "actions": [
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "1695"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "1695"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "3"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "1654"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "13"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "28"
    //                 }
    //             ],
    //             "date_start": "2024-01-10",
    //             "date_stop": "2024-01-10"
    //         },
    //         {
    //             "impressions": "9525",
    //             "actions": [
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "1770"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "1770"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "3"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "1738"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "13"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "19"
    //                 }
    //             ],
    //             "date_start": "2024-01-11",
    //             "date_stop": "2024-01-11"
    //         },
    //         {
    //             "impressions": "8399",
    //             "actions": [
    //                 {
    //                     "action_type": "post",
    //                     "value": "1"
    //                 },
    //                 {
    //                     "action_type": "page_engagement",
    //                     "value": "1523"
    //                 },
    //                 {
    //                     "action_type": "post_engagement",
    //                     "value": "1523"
    //                 },
    //                 {
    //                     "action_type": "onsite_conversion.messaging_conversation_started_7d",
    //                     "value": "3"
    //                 },
    //                 {
    //                     "action_type": "video_view",
    //                     "value": "1495"
    //                 },
    //                 {
    //                     "action_type": "post_reaction",
    //                     "value": "10"
    //                 },
    //                 {
    //                     "action_type": "link_click",
    //                     "value": "17"
    //                 }
    //             ],
    //             "date_start": "2024-01-12",
    //             "date_stop": "2024-01-12"
    //         }
    //     ],
    //     "paging": {
    //         "cursors": {
    //             "before": "MAZDZD",
    //             "after": "MjQZD"
    //         },
    //         "next": "https://graph.facebook.com/v18.0/act_771248076947707/insights?access_token=EAAlDWdudnioBOzzf5ZCeZAp5b16MHNPpnPBHmZB81HVXNrRrAMeABgt4CsxyD8gZCeZBrRT4PFlkImrSwoTqH93bbzNkKtYCxgstJtho60a4AUBnZBrYmSLVOazZBEUiDa8uulpIJ1yAJC3nWxINsgsiuwqKYmU7aQ1rY4uSVZCVtBkfZC0oCVT5SE2ZChe4gNE4xHn3ZBgN0FlThHlD08cVDPy7jvCYk9IcXiwtQZDZD&fields=impressions%2Cactions&pretty=0&time_increment=1&time_range=%7B%27since%27%3A%272023-12-19%27%2C%27until%27%3A%272024-01-17%27%7D&limit=25&after=MjQZD"
    //     }
    // }
    // const ad_account_id_euro = '561176121238314'
    // const ad_account_id = '771248076947707'
    // const insights_access_token = 'EAAlDWdudnioBOwn31rZAX5lz4ZAOwZASn4noqE7hnyebRD20dAtvvBACoTTjztlRcdGikREoPDEqSJ8p8oGTtduvZAfDOEPEyxx4QW30knwaKdjQ1ON0sCacaXsEPw471TBXhKhsZBSENHxiZC3WA6qXbraQMXX644FjQP4qVYeYjNUBQPnHl0jBCx0aj1jQ1GMShNFZB14bh8p1XoB'
    let fetchUrl = 'https://graph.facebook.com/v18.0/act_' + ad_account_id + '/insights?fields=actions,impressions,clicks,spend&limit=730&time_range={"since":"' + getStringDate(dataInicio) + '","until":"' + getStringDate(dataFim) + '"}&time_increment=1&access_token=' + insights_access_token

    const totalLeads = relatorios ? getTotalLeads(relatorios.data) : null
    const totalSpend = relatorios ? getTotalSpend(relatorios.data) : null
    const totalCPA = relatorios ? totalSpend / totalLeads : null
    const getMappedData = () => {
        let eachDayValues = relatorios ? formatDataToEachDayObject(relatorios.data) : null

        return ([
            {
                id: 'Leads',
                color: "rgb(61, 61, 61)",
                data: eachDayValues ? fillMissingDates(eachDayValues, dataInicio, dataFim, metric) : null
            }
        ])
    }
    return (
        <DataRequestComponent
            currState={currState}
            setCurrState={setCurrState}
            fetchUrl={fetchUrl}
            stateProp="relatorios"
        >
            <div className="main-container">
                <div className="relatorios-dash">
                    <header>
                        <p>Relatórios - Meta Ads</p>
                        <div className="relatorios-date-picker">
                            <ReactDatePicker locale={ptBR} dateFormat='dd/MM/yyyy' selected={dataInicio} onChange={(date) => setCurrState({ ...currState, dataInicio: date })} />
                            <FontAwesomeIcon icon={faCalendar} />
                            <ReactDatePicker locale={ptBR} dateFormat='dd/MM/yyyy' selected={dataFim} onChange={(date) => setCurrState({ ...currState, dataFim: date })} />
                        </div>
                        <select name="metric" id="metric" onChange={e => setMetric(e.target.value)}>
                            <option value="onsite_conversion.messaging_conversation_started_7d">Leads</option>
                            <option value="spend">Orçamento</option>
                            <option value="impressions">Impressões</option>
                            <option value="link_click">Cliques</option>
                            <option value="post_engagement">Engajamento com Posts</option>
                            <option value="video_view">Visualização de Vídeo</option>
                            <option value="post_reaction">Reações em Posts</option>
                            <option value="onsite_conversion.post_save">Posts Salvos</option>
                        </select>
                    </header>
                    <div className="relatorios-grid">
                        <Box title={"Leads"} description={"Total de pessoas que entraram em contato"} value={totalLeads} icon={faUsers} />
                        <Box title={"Orçamento"} description={"Custo total da campanha"} value={`R$ ${totalSpend && totalSpend.toFixed(2)}`} icon={faSackDollar} />
                        <Box title={"Custo por Lead"} description={"Valor médio pago por cada novo Lead"} value={`R$ ${totalCPA && totalCPA.toFixed(2)}`} icon={faCommentsDollar} />
                        <Box title={"ROI"} description={"Retorno sobre o investimento em anúncios"} value={"---%"} icon={faMoneyBillTrendUp} />
                        <div className="graph1">
                            <LineChartTimeAxis data={getMappedData()} legend={metricNames[metric]} />
                        </div>
                    </div>
                </div>
            </div>
        </DataRequestComponent>
    )
}

export default Relatorios