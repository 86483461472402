import { addDays, getStringDate } from './dateFunctions'

export function fillMissingDates(data, date_start, date_end, metric) {
    if (date_start > date_end) return null

    let formattedData = []
    let currentDate = date_start
    let y
    while (currentDate <= date_end) {
        let currentStringDate = getStringDate(currentDate)
        if (metric != "spend" && metric != "impressions") {
            y = data[currentStringDate] ? data[currentStringDate].actions.find(action => action.action_type == metric) : null
            y = y ? y.value : 0
        }
        else {
            console.log(data)
            y = data[currentStringDate] ? data[currentStringDate][metric] : 0
            // y = y ? y.value : 0
        }
        let dateObject = {
            x: currentStringDate,
            y: y
        }
        formattedData.push(dateObject)
        currentDate = addDays(currentDate, 1);
    }
    return formattedData
}
export function getTotalSpend(data) {
    let totalSpend = 0;
    data.forEach(item => {
        totalSpend += Number(item.spend);
    });
    return totalSpend;
}

export function getTotalLeads(data) {
    let totalOnsiteConversion = 0;
    for (let item of data) {
        if (!item.actions) continue
        let messagesStarted = item.actions.find(action => action.action_type === "onsite_conversion.messaging_conversation_started_7d")
        if (messagesStarted) totalOnsiteConversion += Number(messagesStarted.value);

    }
    return totalOnsiteConversion;
}