import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Box = props => {
    const { title, value, description, icon } = props
    return (
        <div className="data-box">
            <div className="data-icon">
                <div className="data-icon-wrapper">
                    <FontAwesomeIcon icon={icon} size="2xl" />
                </div>
            </div>
            <div className="data-content">
                <h4>{title}</h4>
                <h2>{value}</h2>
                <p>{description}</p>
            </div>
        </div>
    )
}

export default Box