import React, { useState, useEffect } from "react";
import { useSignIn } from "react-auth-kit";
import './Login.css'
import unlimitedLogo from '../../assets/unlimited_logo.PNG'
import { submitLogin } from "../../utils/login";



const Login = props => {
    const { currState, setCurrState } = props
    const [user, setUser] = useState({
        username: null,
        password: null,
        errorMessage: null
    })
    const [loading, setLoding] = useState(false)
    const signIn = useSignIn()

    const loginClick = async () => {
        setLoding(true)
        let loginData = await submitLogin(user.username, user.password)
        if (loginData.ok) {
            setCurrState({ ...currState, auth: loginData.data })
            signIn({
                token: loginData.token,
                expiresIn: 999999,
                tokenType: 'Bearer',
                authState: { ...loginData.data }
            })

        } else {
            if (loginData.msg) setUser({ ...user, errorMessage: loginData.msg })
        }
        setLoding(false)
    }


    return (
        <>
            <div></div>
            <div className="fc">
                <div className="login-container">
                    <img src={unlimitedLogo} alt="" srcset="" />
                    <p>Usuário</p>
                    <input onChange={e => setUser({ ...user, username: e.target.value })} />
                    <p>Senha</p>
                    <input type="password" onChange={e => setUser({ ...user, password: e.target.value })} />
                    <p className="hide">Cliente</p>
                    <span className="login-loading-circle hide"></span>
                    {loading ? <span class="loader" style={{ marginTop: '32px' }}></span> :
                        <button className="login-btn" onClick={() => loginClick()}>Login</button>}
                    <p className="login-error-msg">{user.errorMessage ? user.errorMessage : ''}</p>
                </div>
            </div>
        </>

    )
}

export default Login