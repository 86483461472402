import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AsideItem = props => (
    <div className="aside-item">
        <FontAwesomeIcon icon={props.icon} />
        <span>{props.title}</span>
    </div>
)

export default AsideItem