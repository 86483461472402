import React from "react";

const ConfigField = props => {
    const { name, value, type } = props
    return (
        <div className="config-field">
            <p>{name}</p>
            <input type={type} value={value} disabled />
        </div>
    )
}

export default ConfigField